<template>
  <v-list-item :class="dash ? 'pl-0 mb-0' : ' mb-0'" @click="goTo()">
    <v-list-item-title class="notification-title">{{
      $t(traduccion, {
        name: name,
        tipo: tipo != null ? tipo : "",
        time: time != null ? time : "",
        change: change != null ? change : "",
      })
    }}</v-list-item-title>

    <v-list-item-action class="my-2">
      <v-icon v-text="icon" small />
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "NofificationsItem",
  props: ["item", "dash"],
  computed: {
    type() {
      let type = this.item.type;
      type = type.split("\\");
      return type[type.length - 1];
    },
    traduccion() {
      let text = `notifications.`;

      switch (this.type) {
        case "NewAppointmentNotification":
          if (this.item.data.setting == "week") {
            text += "new_appointment_week";
          } else if (this.item.data.setting == "month") {
            text += "new_appointment_month";
          } else if (this.item.data.setting == "asap") {
            text += "new_appointment_asap";
          } else {
            text += "new_appointment";
          }
          break;
        case "NewPendingInscriptionUserNotification":
        case "NewPendingInscriptionNotification":
          if (this.item.data.setting == "week") {
            text += "new_appointment_week";
          } else if (this.item.data.setting == "month") {
            text += "new_appointment_month";
          } else {
            text += "new_appointment";
          }
          break;
        case "NewTattooerNotification":
        case "NewStudioNotification":
          text += "new_invitation";
          break;
        case "PayedNotification":
          text += "payed";
          break;
        case "PayedExtraNotification":
          text += "payed_extra";
          break;
        case "TokenPaymentNotification":
          text += "token_payment";
          break;
        case "DisableArtistNotification":
          text += "disable_artist";
          break;
        case "ReactiveTattooerNotification":
          text += "reactive";
          break;
        case "AddReservationBoxNotification":
          text += "reservation_box";
          break;
        case "AssignAppointmentBoxNotification":
          text += "reservation_box_appointment";
          break;
        case "CommissionsNotification":
          text += "commissions";
          break;
        case "TypeArtistNotification":
          text += "type_artist";
          break;
        case "PlanNotification":
          text += "plan";
          break;
        case "NewSessionNotification":
          text += "new_session";
          break;
        case "DeleteSessionNotification":
          text += "delete_session";
          break;
        case "ShareArchiveNotification":
          text += "share_archive";
          break;
        case "NewDesignAddedNotification":
          text += "add_design";
          break;
        case "ReminderNotification":
          text += "reminder";
          break;
        case "EndingTrialNotification":
          text += "ending_trial";
          break;
        case "NewAssistanceAppointmentNotification":
        case "AssaingTattooer":
          text += "assistance_appointment";
          break;

        default:
          text += this.type;
      }
      if (this.type !== "ReminderNotification")
        text += this.$store.getters["auth/isStudio"] ? "_studio" : "_tattooer";
      return text;
    },
    name() {
      if (this.type === "ReminderNotification") {
        return (
          this.item.data.reminder.title || this.item.data.reminder.description
        );
      }
      console.log(this.type, this.item);
      switch (this.type) {
        case "AssaingTattooer":
        case "PayedNotification":
        case "PayedExtraNotification":
        case "NewPendingInscriptionNotification":
        case "NewAssistanceAppointmentNotification":
          return this.item.data.customer_name;

        case "TypeArtistNotification":
          return (
            this.item.data.tattooer_name.user.first_name +
            " " +
            this.item.data.tattooer_name.user.last_name
          );
        case "NewAppointmentNotification":
        case "AssignAppointmentBoxNotification":
          return this.item.data.customer_name;
        case "PlanNotification":
          if (
            this.item.data.change_plan === false &&
            this.item.data.studio_name.plan_id === 3
          )
            return "Ninguno";
          switch (this.item.data.plan_actual) {
            case 1:
              return "Bronze";
            case 2:
              return "Silver";
            case 3:
              return "Gold";
          }
        case "DeleteSessionNotification":
        case "NewSessionNotification":
          let last = this.item.data.date.customer.user.last_name
            ? this.item.data.date.customer.user.last_name
            : "";
          return this.item.data.date.customer.user.first_name + " " + last;
        default:
          return this.$store.getters["auth/isStudio"]
            ? this.item.data.tattooer_name
            : this.item.data.studio_name;
      }
    },
    time() {
      if (this.type === "ReminderNotification") {
        return (
          this.item.data.reminder.title || this.item.data.reminder.description
        );
      }
      //console.log(this.type, this.item.data);

      switch (this.type) {
        case "AddReservationBoxNotification":
        case "AssignAppointmentBoxNotification":
          return (
            this.$d(
              new Date(this.item.data.date.start_date.substring(0, 10)),
              "date2digits"
            ) +
            " - " +
            this.item.data.date.start_time
          );
        case "NewDesignAddedNotification":
        case "NewPendingInscriptionNotification":
        case "NewAppointmentNotification":
          if (
            this.item.data.setting == "week" ||
            this.item.data.setting == "month"
          ) {
            return this.item.data.date.available_dates[0]
              ? this.$d(
                  new Date(
                    this.item.data.date.available_dates[0].date.substring(0, 10)
                  ),
                  "date2digits"
                )
              : "-";
          } else if (this.item.data.setting == "asap") {
            return "";
          } else {
            return this.item.data.date.available_dates[0]
              ? this.traductionTime(this.item.data.date.available_dates[0].date)
              : "-";
          }
        case "DeleteSessionNotification":
        case "NewSessionNotification":
          return this.traductionTime(
            this.item.data.date.available_dates[
              this.item.data.date.available_dates.length - 1
            ].date
          );
        default:
          return null;
      }
    },

    tipo() {
      if (this.type === "ReminderNotification") {
        return (
          this.item.data.reminder.title || this.item.data.reminder.description
        );
      }

      switch (this.type) {
        case "TypeArtistNotification":
          //console.log(this.type, this.item.data.type_id.type_artist);
          let str = [];
          this.item.data.type_id.type_artist.forEach((element) => {
            str.push(this.$tc(element.name));
          });

          return str;
        default:
          return null;
      }
    },
    change() {
      if (this.type === "ReminderNotification") {
        return (
          this.item.data.reminder.title || this.item.data.reminder.description
        );
      }
      switch (this.type) {
        case "PlanNotification":
          switch (this.item.data.studio_name.plan_id) {
            case 1:
              return "Bronze";
            case 2:
              return "Silver";
            case 3:
              return "Gold";
          }

        default:
          return null;
      }
    },
    icon() {
      switch (this.type) {
        case "NewAppointmentNotification":
        case "NewDesignAddedNotification":
        case "NewPendingInscriptionNotification":
        case "PayedNotification":
          return "$calendar";
        case "NewTattooerNotification":
          return "$edit";

        case "AddReservationBoxNotification":
        case "AssignAppointmentBoxNotification":
          return "$boxes";
        default:
          return "mdi-bell-outline";
      }
    },
  },
  methods: {
    traductionTime(time) {
      let data = this.$d(new Date(time.substring(0, 10)), "date2digits");
      data += " - " + time.substring(11, 16);
      return data;
    },
    ...mapActions("auth", ["markNotification"]),
    goTo() {
      this.$router.push({ name: "notifications" });

      return;
      if (this.type !== "ReminderNotification")
        this.markNotification(this.item.id);
      switch (this.type) {
        case "NewAppointmentNotification":
        case "DeleteSessionNotification":
        case "NewSessionNotification":
        case "NewDesignAddedNotification":
        case "PayedNotification":
        case "ShareArchiveNotification":
        case "NewPendingInscriptionNotification":
          this.$router.push({
            name: "appointmentView",
            params: { id: this.item.data.appointment_id },
          });
          break;
        case "NewTattooerNotification":
          //console.log();
          if (
            this.item.data.tattooer.tattooer_studios &&
            this.item.data.tattooer.tattooer_studios[0].status !== "confirmed"
          ) {
            this.$router.push({
              name: "accept_tattooer_invite",
              params: {
                studio: this.item.data.studio_id,
                hash: this.item.data.tattooer.tattooer_studios[0].hash,
                tattooer: this.$store.state.auth.user.id,
              },
            });
          } else {
            this.$router.push({
              name: "studioView",
              params: {
                id: this.item.data.studio_id,
              },
            });
          }
          break;

        case "AddReservationBoxNotification":
        case "AssignAppointmentBoxNotification":
          //console.log(this.item.data);
          this.$router.push({
            name: "boxList",
            params: {
              time: this.item.data.date.start_date,
            },
          });
          break;
        case "PlanNotification":
          //console.log(this.item.data);
          this.$router.push({
            name: "UserSettings",
            params: { type: "myplan" },
          });
          break;
        case "NewStudioNotification":
          this.$router.push({
            name: "existing_studio_invite",
            params: {
              studio: this.item.data.studio_id,
              tattooer: this.item.data.tattooer_id,
              hash: this.item.data.hash,
            },
          });
          break;
        case "ReminderNotification":
          this.$confirm(
            this.item.data.reminder.description,
            this.item.data.reminder.title
          ).then(() => {
            this.markNotification(this.item.id);
          });
      }
    },
  },
};
</script>



<style lang="sass">
.v-menu__content .v-list .v-list-item__title
  &.notification-title
    font-size: 13px
</style>

<style scoped lang="sass">
.v-list-item
  min-height: 0px
</style>